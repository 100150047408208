import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translation: {
                    Home: 'Home',
                    Users: 'Users',
                    Roles: 'Roles',
                    Analytics: 'Analytics',
                    AccountSettings: 'Account Settings',
                    GoodMorning: 'Good Morning',
                    GoodEvening: 'Good Evening',
                    Dashboard: 'Dashboard',
                    SelectLanguage: 'Select language',
                    EnterCredsContinue: 'Enter your credentials to continue',
                    LoginEmailUsername: 'Login with email or username',
                    Login: 'Login',
                    Logout: 'Logout',
                    ForgotPassword: 'Forgot my password',
                    DontHaveAcount: "Don't  have an account?",
                    LoginSuccess: 'Login successful',
                    SomethingWrong: 'Something went wrong!',
                    Email: 'Email',
                    Password: 'Password',
                    ConfirmPassword: 'Confirm password',
                    RequiredField: 'Required field',
                    CreateAccount: 'Create account',
                    YourCreds: 'Enter your credentials',
                    AcceptTerms: 'Accept terms and conditions',
                    AcceptTermsError: 'You have to accept the terms and conditions',
                    AlreadyAccount: 'Already have an account?',
                    SignUp: 'Sign up',
                    Firstname: 'Firstname',
                    Lastname: 'Lastname',
                    PhoneNumber: 'Phone number',
                    PasswordsNotMatching: 'Passwords do not match',
                    InvalidDate: 'Invalid date',
                    InvalidPhone: 'Invalid phone',
                    InvalidEmail: 'Invalid email',
                    SiteUnavailable: 'Site is temporarily unavailable :(',
                    PageNotFound: 'Page not found :(',
                    PageUnderConstruction: '🚚 Page is under construction 🚚'
                }
            },
            gre: {
                translation: {
                    Home: 'Αρχική',
                    Users: 'Χρήστες',
                    Roles: 'Ρολοι',
                    Analytics: 'Aναλυτικά στοιχεία',
                    AccountSettings: 'Ρυθμίσεις λογαριασμού',
                    GoodMorning: 'Καλημέρα',
                    GoodEvening: 'Καλησπέρα',
                    Dashboard: 'Ταμπλό',
                    SelectLanguage: 'Επιλογή γλώσσας',
                    EnterCredsContinue: 'Εισάγεται τα στοιχεία σας για να συνεχίσετε',
                    LoginEmailUsername: 'Σύνδεση μέσω Email ή Όνομα Χρήστη',
                    Login: 'Σύνδεση',
                    Logout: 'Αποσύνδεση',
                    ForgotPassword: 'Ξέχασα τον κωδικό μου',
                    DontHaveAcount: 'Δεν έχετε λογαριασμό;',
                    LoginSuccess: 'Επιτυχία σύνδεσης',
                    SomethingWrong: 'Κάτι πήγε λάθος',
                    Email: 'Δ/νση Ηλ. Ταχυδρομίου',
                    Password: 'Κωδικός πρόσβασης',
                    ConfirmPassword: 'Επιβεβαιώση κωδικού πρόσβασης',
                    RequiredField: 'Υπορχρεωτικό πεδίο',
                    CreateAccount: 'Δημιουργία λογαριασμού',
                    YourCreds: 'Καταχωρήστε τα στοιχεία σας',
                    AcceptTerms: 'Αποδεχθείτε τους όρους χρήσης',
                    AcceptTermsError: 'Πρέπει να αποδεχθείτε τους όρους χρήσης',
                    AlreadyAccount: 'Έχετε ήδη λογαριασμό;',
                    SignUp: 'Εγγραφή',
                    Firstname: 'Όνομα',
                    Lastname: 'Επώνυμο',
                    PhoneNumber: 'Αριθμός τηλεφώνου',
                    PasswordsNotMatching: 'Οι κωδικοί δεν ταιριάζουν',
                    InvalidDate: 'Μη έγκυρη ημερομηνία',
                    InvalidPhone: 'Μη έγκυρος αριθμός τηλεφώνου',
                    InvalidEmail: 'Μη έγκυρο email',
                    SiteUnavailable: 'Η ιστοσελίδα είναι προσωρινά μη διαθέσιμη :(',
                    PageNotFound: 'Η σελίδα δεν βρέθηκε :(',
                    PageUnderConstruction: '🚚 Η σελίδα είναι υπό κατασκευή 🚚'
                }
            }
        }
    });

export default i18n;
