/* eslint-disable import/no-cycle */
import axiosInstance from '../axiosInstance';

export const authApi = {
    post: async (path, params, cancelToken) =>
        axiosInstance
            .post(`/timeKeeping${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err.error ? err : err?.response;
            }),
    get: async (path, params, cancelToken) =>
        axiosInstance
            .get(
                `/timeKeeping${path}`,
                { params },
                {
                    cancelToken: cancelToken?.token
                }
            )
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response;
            })
};

export const getTimeKeeping = async () => authApi.get('/GetTimeKeepingView');
