import axios from 'axios';
import { store } from 'store';
import { LOGOUT, REFRESH_TOKEN } from 'store/actions';
import moment from 'moment';
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import { refreshToken } from 'apis/controllers/auth';

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL
});

axiosInstance.interceptors.request.use((config) => {
    if (config.data) {
        Object.keys(config.data).forEach(async (key) => {
            // Find a valid date object and format it
            if (config.data[key] instanceof Date && !Number.isNaN(config.data[key])) {
                config.data[key] = moment(config.data[key]).format('YYYY-MM-DDTHH:mm');
            }
        });
    }
    const state = store.getState();
    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${state.auth.token}`
    };
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        console.log('error', error);
        const originalRequest = error.config;
        if (error?.message === 'Network Error') {
            const errorObject = {
                response: {
                    message: error.message,
                    status: 503
                }
            };
            return Promise.reject(errorObject);
        }
        if (error?.response?.status === 453 && !originalRequest._retry) {
            const { dispatch } = store;
            dispatch({ type: LOGOUT });
        }
        if (error?.response?.status === 401 && !originalRequest._retry) {
            const state = store.getState();
            const { dispatch } = store;
            originalRequest._retry = true;
            try {
                const refreshResponse = await refreshToken({
                    refreshToken: state.auth.refreshToken
                });
                dispatch({ type: REFRESH_TOKEN, payload: refreshResponse });
                return axiosInstance(originalRequest);
            } catch (ex) {
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
